<template>
  <div class="checkout">
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
    />

    <v-container fluid class="checkout-title">
      <CategoryTitle :category="category" :showOnMobile="true" />
    </v-container>
    <v-container fluid class="pt-0">
      <v-row>
        <v-col cols="12">
          <ResponseMessage :response="response" />
        </v-col>
        <v-col cols="12" class="py-0">
          <CheckoutConfirmEmail
            v-if="!cart.user.profile.confirmed"
            :key="cart.refreshTime"
            @sendEmail="sendActivationMail"
          />
        </v-col>
        <v-col cols="12" md="8" class="pt-0">
          <div class="checkout-content mb-5">
            <v-card outlined>
              <v-card-title>
                {{ $t("checkout.giftCodeTitle") }}
              </v-card-title>

              <v-card-text>
                <GiftCode
                  v-if="!get(category, 'metaData.template_model.HIDE_GIFT')"
                  :giftCertificates="giftCertificates"
                  @add="loadGiftCertificates"
                  @remove="loadGiftCertificates"
                />
              </v-card-text>
            </v-card>

            <!-- warehouseId 356 is google box  -->
            <div
              class="d-flex flex-column flex-sm-row mt-6"
              :key="cart.grossTotal"
              v-if="
                isCheckoutAwardsDateEnable &&
                  filteredCheckoutAwardsProducts &&
                  points &&
                  cart.warehouse.warehouseId !== 356
              "
            >
              <!-- v-if="
                  points > filteredCheckoutAwardsProducts.points &&
                    index === 0 &&
                    filteredCheckoutAwardsProducts.discount <=
                      0.8 * cart.grossTotal
                " -->
              <CheckoutAwardsProduct
                class="mb-3 mr-sm-5"
                :productData="filteredCheckoutAwardsProducts"
              />
            </div>

            <v-card
              v-if="
                cart.warehouse.warehouseId !== 356 &&
                  moneyCheckout > 0 &&
                  $dayjs(cart.timeslot.date).date() >= purseEnableDate
              "
            >
              <v-card-title>{{
                $t("checkout.useBorsellinoTitle")
              }}</v-card-title>
              <v-card-text class="checkout-promo">
                <v-checkbox
                  dense
                  color="primary"
                  hide-details
                  v-model="useBorsellino"
                  true-value="true"
                  false-value="false"
                  class="accept-alternatives pt-0 mr-2"
                >
                  <template v-slot:label>
                    <span>{{
                      $t("checkout.useBorsellinoCheckbox", {
                        money: $n(moneyCheckout, "currency")
                      })
                    }}</span>
                    <v-tooltip top max-width="275">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>
                            $info
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t("checkout.useBorsellinoTooltip") }}</span>
                    </v-tooltip>
                  </template>
                </v-checkbox>
              </v-card-text>
            </v-card>

            <!-- <GiftList
              class="mt-6 mb-3"
              :title="$t('checkout.giftCodeSubtitle')"
              :mode="'checkout'"
            /> -->
            <v-alert
              v-if="error"
              class="mt-5"
              type="error"
              border="left"
              transition="v-fab-transition"
            >
              {{ error }}
            </v-alert>
          </div>
          <div class="mb-5">
            <CartInfoServiceCard
              :shippingAddress="cart.shippingAddress"
              :editable="editableAddress"
              class="w-100"
              @edit="goToServiceSelector"
            />
          </div>
          <div class="mb-5">
            <CartInfoAddressCard
              :shippingAddress="cart.shippingAddress"
              :editable="editableAddress"
              class="w-100"
              @edit="openAddressSelector"
            />
          </div>
          <div class="mb-5">
            <CartInfoTimeslotCard
              :shippingAddress="cart.shippingAddress"
              :timeslot="cart.timeslot"
              :editable="editableTimeslot"
              class="w-100 cart-info-timeslot-card"
              :value="timeslot"
              @edit="openTimeslotSelector"
            />
          </div>
          <div
            class="checkout-actions d-flex flex-column flex-sm-row justify-start align-center "
          >
            <v-btn
              color="primary"
              class="mb-5 mb-sm-0 mr-sm-5"
              :block="$vuetify.breakpoint.xs"
              x-large
              outlined
              depressed
              :to="{ name: 'Cart' }"
            >
              <span>{{ $t("cart.button.goToCartDetail") }}</span>
            </v-btn>
            <v-btn
              color="primary"
              class="mb-5 mb-sm-0"
              :block="$vuetify.breakpoint.xs"
              x-large
              outlined
              depressed
              :to="{ name: 'Home' }"
            >
              <span>{{ $t("cart.button.continueShopping") }}</span>
            </v-btn>
            <v-spacer />
            <v-btn
              class="margin-left-auto center-btn"
              color="primary"
              x-large
              depressed
              :block="$vuetify.breakpoint.xs"
              :disabled="profileLevel < minProfileLevel || cart.totalItems == 0"
              :loading="loading"
              @click="goToPayment"
            >
              <span>{{ $t("checkout.goToPaymentBtn") }}</span>
            </v-btn>
          </div>
          <category-block
            :target="category"
            :container="false"
            position="position2"
            class="category-block category-block-2"
          />
        </v-col>

        <v-col cols="12" md="4" order="first" order-md="last" class="pt-0">
          <CheckoutCompleteProfile v-if="profileLevel < minProfileLevel" />

          <v-card>
            <v-card-text>
              <!-- <CheckoutCompleteProfile v-if="profileLevel < minProfileLevel" /> -->
              <!-- <GiftCode
                :giftCertificates="giftCertificates"
                class="mb-3 py-1"
                @add="loadGiftCertificates"
                @remove="loadGiftCertificates"
              /> -->

              <!-- <template
                v-if="
                  giftCertificatesReceived &&
                    giftCertificatesReceived.length > 0
                "
              >
                <div
                  v-for="gift in giftCertificatesReceived"
                  :key="gift.userGiftCertificateId"
                  class="gift-certificate-received d-flex align-center py-4"
                >
                  <v-avatar color="secondary" class="mr-4" size="48" rounded>
                    <span class="white--text text-h5">
                      {{ gift.amount }}&euro;
                    </span>
                  </v-avatar>
                  <div class="gift-certificate-content flex-grow-1">
                    <span class="text-body-2 font-weight-bold">
                      {{ gift.description }}
                    </span>
                    <div
                      v-if="gift.endDate"
                      class="expiration-date text-caption grey lighten-3"
                      v-html="
                        `<span class='font-weight-bold text-uppercase'>${$t(
                          'checkout.expires'
                        )}</span>: ${$t('benefits.endDate')} 
                ${$dayjs(gift.endDate).format('DD/MM')}`
                      "
                    ></div>
                  </div>
                  <v-btn
                    color="primary"
                    depressed
                    small
                    @click.stop="addUserGift(gift.userGiftCertificateId)"
                  >
                    {{ $t("benefits.useBtn") }}
                  </v-btn>
                </div>
              </template> -->
              <CheckoutSummary :orderCart="cart" @removeGiftCode="removeGift" />

              <DeliveryFee class="mt-5" :showList="true" />

              <category-block
                :target="category"
                :container="false"
                position="position3"
                class="category-block category-block-3"
              />
              <category-block
                :target="category"
                :container="false"
                position="position4"
                class="category-block category-block-4"
              />
            </v-card-text>

            <v-card-actions>
              <v-btn
                class="mt-5 w-100"
                color="primary"
                x-large
                :block="$vuetify.breakpoint.xs"
                depressed
                :disabled="
                  profileLevel < minProfileLevel || cart.totalItems == 0
                "
                :loading="loading"
                @click="goToPayment"
              >
                <span>{{ $t("checkout.goToPaymentBtn") }}</span>
              </v-btn>
            </v-card-actions>
          </v-card>
          <ProposalSlider :proposals="getProposals('sidebar-banner')" />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<style lang="scss">
.checkout {
  .cart-info-timeslot-card {
    .value {
      text-transform: uppercase;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .center-btn {
      width: 100%;
      margin-right: 0px !important;
      margin-bottom: 8px !important;
    }
  }
  .privacy-check {
    a {
      color: var(--v-default-base) !important;
      font-weight: bold;
    }

    @media #{map-get($display-breakpoints, 'xs-only')} {
      .checkout-title {
        h1 {
          font-size: 24px !important;
        }
      }
    }
  }
  .qty-wrap {
    max-width: 160px;
  }
  .margin-left-auto {
    margin-left: auto;
  }
}
</style>
<script>
import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CheckoutCompleteProfile from "@/components/cart/CheckoutCompleteProfile.vue";
import CheckoutConfirmEmail from "@/components/cart/CheckoutConfirmEmail.vue";
import GiftCode from "@/components/gift/GiftCode.vue";
import CartInfoServiceCard from "@/components/cart/CartInfoServiceCard.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import DeliveryFee from "@/components/delivery/DeliveryFee.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import CheckoutAwardsProduct from "@/components/awards/CheckoutAwardsProduct.vue";
import GiftProductSelector from "@/components/gift/GiftProductSelector.vue";

import AnalyticsService from "~/service/analyticsService";
import ListService from "~/service/listService";
import RegistrationService from "~/service/userService";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import pointsMixins from "@/mixins/pointsMixins";
import categoryMixins from "~/mixins/category";

import { forEachCartItem } from "~/service/ebsn.js";
import { mapActions, mapState, mapGetters } from "vuex";
import { mapCartInfo } from "~/service/ebsn";

// import reduce from "lodash/reduce";
// import forEach from "lodash/forEach";
// import cloneDeep from "lodash/cloneDeep";

import get from "lodash/get";
// import GiftList from "@/components/gift/GiftList.vue";

export default {
  name: "Checkout",
  mixins: [login, cartInfo, categoryMixins, pointsMixins],
  components: {
    CategoryTitle,
    CheckoutSummary,
    GiftCode,
    CheckoutCompleteProfile,
    CheckoutConfirmEmail,
    CartInfoServiceCard,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    DeliveryFee,
    ResponseMessage,
    // GiftList,
    CheckoutAwardsProduct
  },
  data() {
    return {
      error: null,
      loading: false,
      response: {},
      minProfileLevel: global.config.minProfileLevel,
      giftCertificates: [],
      emailKey: 1,
      packages: true,
      selectedOptions: {},
      iosMobile: false,
      dialogInstance: null
      // checkoutAwardsProducts: [
      //   {
      //     promoCode: "BS10EO",
      //     productId: 94529,
      //     points: 2500,
      //     discount: 10
      //   },
      //   {
      //     promoCode: "BS5EO",
      //     productId: 94530,
      //     points: 1500,
      //     discount: 5
      //   }
      // ]
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart,
      applicationConfig: ({ custom }) => custom.applicationConfig
    }),
    ...mapGetters({
      profileLevel: "cart/profileLevel"
    }),
    ...mapCartInfo({
      useBorsellino: "use_borsellino"
    }),
    purseEnableDate() {
      return this.applicationConfig.PURSE_ENABLE_DATE
        ? this.applicationConfig.PURSE_ENABLE_DATE
        : 22;
    },
    checkoutAwardsBeginDate() {
      return this.applicationConfig.CHECKOUT_AWARDS_BEGIN_DATE;
    },
    checkoutAwardsEndDate() {
      return this.applicationConfig.CHECKOUT_AWARDS_END_DATE;
    },
    checkoutAwardsProducts() {
      if (this.applicationConfig.CHECKOUT_AWARDS_PRODUCTS) {
        return this.applicationConfig.CHECKOUT_AWARDS_PRODUCTS
          .checkoutAwardsProducts;
      } else {
        return [];
      }
    },
    filteredCheckoutAwardsProducts() {
      let filteredProduct;
      for (let i = 0; i < this.checkoutAwardsProducts.length; i++) {
        // if (this.points > this.checkoutAwardsProducts[i].points) {
        if (
          this.checkoutAwardsProducts[i].discount <=
            0.8 * this.cart.grossTotal &&
          this.points > this.checkoutAwardsProducts[i].points
        ) {
          filteredProduct = this.checkoutAwardsProducts[i];
          break;
        }
        // }
      }
      return filteredProduct;
    },
    isCheckoutAwardsDateEnable() {
      return (
        this.$dayjs().isAfter(this.$dayjs(this.checkoutAwardsBeginDate)) &&
        this.$dayjs().isBefore(this.$dayjs(this.checkoutAwardsEndDate))
      );
    },
    editableAddress() {
      return !(this.cart.cartStatusId == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    },
    giftCertificatesReceived() {
      return this.cart.user?.giftCertificatesReceived;
    }
  },

  methods: {
    get: get,
    ...mapActions({
      lockCart: "cart/lockCart",
      emptyCart: "cart/emptyCart",
      removeGiftCode: "cart/removeGiftCode",
      addGiftCode: "cart/addGiftCode",
      loadCart: "cart/loadCart",
      getCart: "cart/getCart"
    }),
    async addUserGift(userGiftCertificateId) {
      await this.addGiftCode(userGiftCertificateId);
    },
    goToServiceSelector() {
      this.$router.push({
        name: "DeliveryServiceView",
        query: { back: this.$route.fullPath }
      });
    },
    async loadGiftCertificates() {
      //this.autoAssignedGifts = await userService.listGiftCertificate("auto");

      let giftCertificates = await RegistrationService.listGiftCertificate(
        "list"
      );
      var manualGiftCertificates = await RegistrationService.listGiftCertificate(
        "manual"
      );

      this.giftCertificates = [
        ...giftCertificates,
        ...manualGiftCertificates
      ].filter(giftCertificate => {
        // if (giftCertificate.giftAdjustmentType.giftAdjustmentTypeId == 21) {
        //   //hide refund
        //   return false;
        // }
        //check user gift
        let hasUserGift = [];
        if (giftCertificate.userGiftCertificates?.length > 0) {
          hasUserGift = giftCertificate.userGiftCertificates.filter(
            userGiftCertificate => {
              return !userGiftCertificate.orderId;
            }
          );
        }
        return !!giftCertificate.giftCode || hasUserGift.length > 0;
      });
      this.giftCertificates = this.giftCertificates.filter(
        gift => !gift?.userGiftCertificates[0]?.descrExtra
      );
    },
    sendActivationMail() {
      this.response = {};
      let _this = this;
      RegistrationService.registrationResendEmail(this.cart.user.email).then(
        function(data) {
          _this.response = data.response;
        },
        function(response) {
          _this.response = response;
        }
      );
    },
    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.smAndDown,
        width: 300
      });
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          this.emptyCart();
        }
      }
    },
    async goToPayment() {
      let _this = this;
      _this.loading = true;
      try {
        if (await this.needLogin("addtocart")) {
          if (await this.needAddress()) {
            if (await this.needTimeslot()) {
              await _this.lockCart();
              _this.$router.push({
                name: "Payment"
              });
            }
          }
        }
      } catch (err) {
        await this.getCart();
        await this.needTimeslot();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    },
    checkOsx() {
      var userAgent = [];
      var version = [];
      var versionNumber = 0;
      if (navigator.userAgent.indexOf("iPhone") > -1) {
        userAgent = navigator.userAgent.split(" ");
        userAgent.filter(ua => {
          if (ua.indexOf("Version/") > -1) {
            version = ua.split("/");
          }
        });
        versionNumber = parseFloat(version[1]);
        if (version[0] == "Version") {
          if (versionNumber < 15) {
            this.iosMobile = true;
          } else {
            this.iosMobile = false;
          }
        } else {
          this.iosMobile = false;
        }
      }
    },
    async showPersonalCouponDialog() {
      this.dialogInstance = await this.$dialog.show(GiftProductSelector, {
        title: "Scegli ii proodtti a cui appliicare i tuoi bolloni",
        waitForResult: false,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      const result = await this.dialogInstance.wait();
      if (result) {
        await this.loadCart();
      }
      return result;
    },
    async removeGift(gift) {
      await this.removeGiftCode(gift);
      this.loadGiftCertificates();
    }
  },
  async mounted() {
    // if (!this.cart.user.profile.confirmed) {
    //   this.sendActivationMail();
    // }

    global.EventBus.$on("resetAlertMessage", () => {
      if (this.error) {
        this.error = false;
      }
    });
    AnalyticsService.beginCheckout(this.cart);
    AnalyticsService.viewProducts(this.cart.cartItems, this.itemListName, 0);
    AnalyticsService.viewCart(this.cart);

    this.checkOsx();
    this.loadGiftCertificates();
  }
};
</script>
