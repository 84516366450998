<template>
  <v-card
    outlined
    v-if="product"
    class="checkout-awards-product-card d-flex flex-column align-center"
  >
    <img class="product-img pa-5" v-if="productImgSrc" :src="productImgSrc" />
    <v-card-text class="product-description">
      <div class="product-id d-none u-identifier" itemprop="mpn">
        {{ product.productId }}
      </div>
      <div
        v-if="
          product.metaData &&
            product.metaData.product_description &&
            product.metaData.product_description.avvertenze
        "
        class="product-name p-name"
        itemprop="name"
        v-html="product.metaData.product_description.avvertenze"
      ></div>
      <div class="product-text-wrapper">
        <div class="product-text">{{ product.description }}</div>
      </div>

      <div
        v-if="
          product.warehousePromo &&
            product.warehousePromo.view &&
            product.warehousePromo.view.header
        "
        v-html="product.warehousePromo.view.header"
        class="award-price"
      ></div>

      <v-card-actions>
        <v-btn
          block
          large
          class="use-checkout-awards-btn"
          @click="sendPromoCode(productData.promoCode)"
          depressed
          color="primary"
        >
          {{ $t("checkout.productAwardAddBtn") }}
        </v-btn>
      </v-card-actions>
    </v-card-text>
  </v-card>
</template>
<style lang="scss">
.checkout-awards-product-card {
}
</style>
<script>
import ProductService from "~/service/productService";
import CartService from "~/service/cartService";

import { mapActions } from "vuex";
import get from "lodash/get";

export default {
  name: "CheckoutAwardsProduct",
  props: {
    productData: { type: Object, required: true }
  },
  data() {
    return {
      product: null
    };
  },
  computed: {
    productImgSrc() {
      return this.product && this.product.mediaURL
        ? this.product.mediaURL
        : get(this.product, "media[0].small");
    }
  },
  methods: {
    ...mapActions({
      getCart: "cart/getCart"
    }),
    async getProductBySlug() {
      try {
        let res = await ProductService.getProductBySlug(
          this.productData.productId
        );
        if (res) {
          this.product = res;
        }
      } catch (err) {
        console.log(err);
      }
    },
    async sendPromoCode(code) {
      try {
        let res = await CartService.sendGiftCode(code);

        if (res) {
          this.getCart();
        }
      } catch (err) {
        console.log(err);
      }
      // .then
      //   function(cart) {
      //     $log.debug("Codice promozionale accettato");
      //     $rootScope.$broadcast("giftCertificate:added", {
      //       message: "Promozione aggiunta al carrello"
      //     });
      //     $rootScope.$broadcast("checkoutAwards:forceCheck");
      //     loadGiftCertificates();
      //     //AnalyticsService.trackEvent("GiftCertificate", "add", giftCertificate.name, giftCertificate.giftCertificateId);
      //   },
      //   function(error) {
      //     vm.error = error;
      //     $log.info(error);
      //   }
      // );
    }
  },
  mounted() {
    this.getProductBySlug();
  }
};
</script>
