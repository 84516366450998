var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.product)?_c('v-card',{staticClass:"checkout-awards-product-card d-flex flex-column align-center",attrs:{"outlined":""}},[(_vm.productImgSrc)?_c('img',{staticClass:"product-img pa-5",attrs:{"src":_vm.productImgSrc}}):_vm._e(),_c('v-card-text',{staticClass:"product-description"},[_c('div',{staticClass:"product-id d-none u-identifier",attrs:{"itemprop":"mpn"}},[_vm._v(" "+_vm._s(_vm.product.productId)+" ")]),(
        _vm.product.metaData &&
          _vm.product.metaData.product_description &&
          _vm.product.metaData.product_description.avvertenze
      )?_c('div',{staticClass:"product-name p-name",attrs:{"itemprop":"name"},domProps:{"innerHTML":_vm._s(_vm.product.metaData.product_description.avvertenze)}}):_vm._e(),_c('div',{staticClass:"product-text-wrapper"},[_c('div',{staticClass:"product-text"},[_vm._v(_vm._s(_vm.product.description))])]),(
        _vm.product.warehousePromo &&
          _vm.product.warehousePromo.view &&
          _vm.product.warehousePromo.view.header
      )?_c('div',{staticClass:"award-price",domProps:{"innerHTML":_vm._s(_vm.product.warehousePromo.view.header)}}):_vm._e(),_c('v-card-actions',[_c('v-btn',{staticClass:"use-checkout-awards-btn",attrs:{"block":"","large":"","depressed":"","color":"primary"},on:{"click":function($event){return _vm.sendPromoCode(_vm.productData.promoCode)}}},[_vm._v(" "+_vm._s(_vm.$t("checkout.productAwardAddBtn"))+" ")])],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }